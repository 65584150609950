.rooms-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--color1-m-20);
  text-transform: uppercase;
}

.rooms-content {
  font-size: 1.06rem;
  line-height: 1.5rem;
  text-align: justify;
  
}

.rooms-img-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1.26rem;
  padding-top: 1.5rem;
}

.rooms-img-item-container {
  position: relative;
}

.rooms-img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.5s ease;
  z-index: 100;
  border: 0.186rem solid var(--color1-m-12);

}

.magnify-icon {
  width: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  cursor: pointer;
}

.rooms-img-item-container:hover .rooms-img {
  filter: brightness(1.1);
}

.rooms-img-item-container:hover .magnify-icon {
  opacity: 1;
  z-index: 100;
}

/* Rooms Rooms  */
.rooms-rooms-container {
  display: grid;
  width: 100%;

  place-items: center;
  background-color: var(--color1-h-3);
}

.rooms-rooms-title {
  font-size: 2rem;
  font-weight: bold;
  padding-top: 3rem;
  color: var(--color1-m-20);
  border-bottom: 1px solid var(--color1-l-14);
  padding-bottom: 0.75rem;
}

.rooms-rooms-item-container {
  display: grid;
  justify-items: center;
  row-gap: 1rem;
}
.rooms-rooms-item {
  display: grid;
  width: 100%;
  justify-items: center;
}

.rooms-rooms-item-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--color1-m-20);
  justify-self: left;
  padding-top: 1.5rem;
  margin-left: 20%;

}

.rooms-item-content {
  font-size: 1rem;
  line-height: 1.7rem;
  padding: 2rem 20% 0 20%;
  text-align: center;
  text-align: justify;
  color: var(--color1-m-19);
  font-size: 1.03rem;

}

.rooms-rooms-item-numbers-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 60%;
  padding-top: 2rem;
  row-gap: 1rem;
  justify-items: left;
}

.rooms-rooms-item-numbers-text {
  font-size: 0.825rem;
  font-weight: 600;
  color: var(--color1-m-19);
}

.rooms-rooms-img-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1.5rem;
  padding-top: 2.25rem;
  width: 60%;
  padding-bottom: 3rem;
  border-bottom: 1px solid var(--color1-m-14);
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .rooms-rooms-img-container {
    width: 90%;
  }

  .rooms-rooms-item-title {
    margin-left: 5%;
  }
  
  .rooms-item-content {
    padding: 2rem 5% 0 5%;
  }
  
  .rooms-rooms-item-numbers-container {
    margin-left: 5%;
    row-gap: 1.5rem;
  }

}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1.1em;
  }
} */
