nav {
  position: fixed;
  width: 100%;
  height: 5.8rem;
  top: 0;
  display: grid;
  justify-items: center;
  user-select: none;
  /* background-color: hsla(240, 14%, 14%, 0.3); */
  /* background-color: transparent; */
  /* background-color: rgba(7, 219, 7, 0.151); */
  /* background-color: transparent; */
  z-index: 1000;
  font-style: normal;
  align-items: center;
  grid-template-columns: 1fr;
}

.nav-bg-normal {
  background-color: rgba(0, 0, 0, 0);
  /* background-color: var(--color1-m-2); */
}

.nav-bg-dark {
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: var(--color-transparent-bg-1);
}

.nav-company-logo {
  /* display: grid; */
  /* justify-items: center;
  align-self: center; */
  justify-self: center;
  height: 4.8rem;
  /* background-color: #333; */
  /* padding: 0.5rem 0; */
}

.nav-menu-items-container {
  display: grid;
  grid-template-columns: repeat(7, max-content);
  column-gap: 3rem;
  width: max-content;
  align-items: center;
  height: 100%;
}

.nav-link {
  /* width: 100%; */
  display: grid;
  align-items: center;
  text-decoration: none;
  justify-items: center;
  /* padding: 0 1rem; */
  /* background-color: #f33838;
  border-right: 2px solid #000; */
  /* width: max-content; */
}

.nav-link-p {
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color1-vh-1);
  text-decoration: none;
  font-size: 1.15rem;
  text-align: center;
  transition-duration: 0s;
  text-shadow: 1px 2px 3px var(--color-bw-22);
  padding-bottom: 0.25rem;
}

.nav-menu-bottom-indicator {
  /* width: 100%; */
  width: calc(100% + 0.2rem);
  height: 0.25rem;
  background-color: var(--color1-vh-8);
  border-radius: 1rem;
  /* -webkit-text-stroke: 1px var(--color1-h-16); */
}

.border-none {
  border: none;
}

.nav-link-p:hover {
  color: var(--color1-vh-3);
  text-shadow: 1px 2px 2px var(--color-bw-22);
}

.nav-toggle-btn {
  position: absolute;
  right: 0.6rem;
  top: 0.9rem;
  padding: 0.3rem;
  filter: invert(1);
  width: 1.8rem;
  z-index: 1000;
}

.nav-toggle-btn:hover {
  filter: invert(0.8);
}

/* Cart Icon */
.nav-cart-container {
  display: grid;
  align-items: center;
  height: 100%;
  justify-items: center;
}

.nav-tems-cart-img {
  width: 2.25rem;
  /* aspect-ratio: 1/1; */
  object-fit: cover;
}

.round-div {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 50%;
  border: 0.2rem solid #f33838;
  background-color: #ffd8d8;
  text-align: center;
  color: #333;
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 10;
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  .nav-link-p {
    font-size: 1.3rem;
  }
}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* @media screen and (max-width: 600px) {
  nav {
    grid-template-columns: 1fr;
    width: 100%;
    height: fit-content;
    padding-bottom: 2rem;
  }

  .nav-menu-items-container {
    grid-template-columns: 1fr;
  }

  .nav-link {
    display: grid;
    align-items: center;
    padding: 0.1rem 0;
  }

  .nav-link-p {
    font-size: 1.55rem;
  }

  .nav-items {
    width: 100%;
    text-align: center;
    height: fit-content;
    padding: 1.1rem 0;
  }

  .nav-toggle-btn {
    display: block;
    padding-right: 1rem;
    padding-top: 1rem;
    width: 3.5rem;
  }

  .nav-company-logo {
    height: 8rem;
  }

  .nav-tems-cart-img {
    width: 3rem;
  }
} */
