.footer-main-container {
  display: grid;
  /* box-sizing: border-box; */
  background-color: var(--color1-m-20);

  grid-template-columns: 1fr 3fr 1fr;
  color: var(--color1-l-6);
  padding: 5rem;
  /* margin-top: 3rem; */
  font-family: "Raleway", "cursive";
  /* width: 100%; */
  /* bottom: 0; */
}

.footer-social-media-container {
  display: grid;
  align-items: flex-start;
  justify-items: center;
  /* row-gap: 0; */
}

.footer-social-title {
  font-size: 1.25rem;
  text-transform: uppercase;
}

.footer-social-img-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: max-content;
  row-gap: 0.9rem;
  column-gap: 0.9rem;
}

.footer-social-img {
  width: 2.8rem;
  cursor: pointer;
  z-index: 10000;
}

.footer-partners-container {
  display: grid;
  align-items: flex-start;
  justify-items: center;
  row-gap: 2rem;
}

.footer-partners-title {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.footer-partners-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  justify-items: center;
  align-items: center;
}

.footer-partners-img1 {
  /* width: 12rem; */
  height: 6rem;
  object-fit: cover;
}

.footer-partners-img2 {
  /* width: 12rem; */
  height: 5rem;
  object-fit: cover;
}

.footer-contact-container {
  display: grid;
}

.footer-contact-title {
  font-size: 1.45rem;
  text-transform: uppercase;
  padding-bottom: 2rem;
}

.footer-contact-name {
  /* padding: 1rem 0; */
  font-size: 1.1rem;
  padding-bottom: 1rem;
}

.footer-contact-address {
  font-size: 1.1rem;
  padding-bottom: 3rem;

  /* padding:1rem 0; */
}

.footer-contact-telephone {
  font-size: 0.98rem;
  padding-bottom: 1rem;
}

.footer-contact-email {
  font-size: 0.98rem;
  /* padding: 1rem 0; */
}

.footer-copyright-text{
  /* justify-self: center; */
  font-size: 0.75rem;
  background-color: var(--color1-m-20);
  color: var(--color1-l-6);
  width: 100%;
  text-align: center;
  padding-bottom: 0.75rem;
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .footer-main-container {
    /* display: grid; */
    /* background-color: var(--color1-m-20); */
  
    grid-template-columns: 1fr 2.5fr 1fr;
    /* color: var(--color1-l-6);
    padding: 5rem;
    font-family: "Raleway", "cursive"; */
  }
}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1.1em;
  }
} */
