.contact-main-container {
  display: grid;
  width: 100%;
  justify-items: center;
  justify-self: center;
}
/* Apcey web section */
.contact-form-main-container {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  align-items: center;
  justify-items: center;
  background-color: var(--color1-h-2);
  padding: 3rem;
  /* min-height: 48rem; */
  color: var(--color1-m-18);
  width: 100%;
}

/* .contact-form-info-container {
  padding: 3rem 14rem;
} */

/* .contact-form-info-description-text {
  font-size: 2.5rem;
  padding: 1rem 0 2rem 0;
}

.contact-form-info-email-title {
  font-size: 1.15rem;
  line-height: 2rem;
}

.contact-form-info-email-text {
  font-size: 1.15rem;
  line-height: 2rem;
  cursor: pointer;
  text-decoration: none;
  color: white;
} */

.contact-get-in-touch-title {
  font-size: 1.75rem;
  color: var(--color1-m-18);
  /* padding-bottom: 2rem; */
}

.contact-form-title {
  font-size: 1.5rem;
  color: var(--color1-m-18);
  /* padding-bottom: 2rem; */
}

.contact-sub-title {
  font-size: 1.1rem;
  /* text-transform: uppercase; */
  padding-top: 2rem;
  font-weight: 600;
  /* color: #808080; */
  color: var(--color1-m-16);
}

.contact-form-sub-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  width: 100%;
  column-gap: 3rem;
  padding-top: 3rem;
}

.contact-form-img {
  width: 25rem;
  /* aspect-ratio: 1.05/1; */
  /* width: 22rem; */
  border: 3px solid var(--color1-m-10);
  object-fit: cover;
  border-radius: 0.15rem;
}

.contact-form-block-container {
  display: grid;
  /* grid-template-columns: 1fr; */
  row-gap: 1.3rem;
  width: 100%;
  /* padding: 3rem 3.5rem; */
  border: 2px solid var(--color1-m-9);
  box-shadow: 2px 2px 3px var(--color1-m-4);
  padding: 3rem;
  border-radius: 0.15rem;
  justify-self: center;
  aspect-ratio: 7/4;
}

.contact-form-block-name-container {
  display: grid;
  grid-template-columns: 5rem 3fr 3fr;
  column-gap: 1rem;
  /* row-gap: 0.5rem; */
  align-items: center;
  justify-items: center;
}

.contact-form-block-item {
  display: grid;
  width: 100%;
}

.contact-form-block-input-title {
  font-size: 1rem;
  padding-bottom: 0.2rem;
  font-weight: 600;
  padding-left: 0.2rem;
  /* text-transform: uppercase; */
}

.contact-form-block-input {
  min-height: 2.35rem;
  padding: 0.1rem 0.55rem;
  font-size: 0.88rem;
  width: 100%;
  color: var(--color1-m-18);
  font-weight: 600;
  /* text-transform: uppercase; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.contact-form-block-input:focus {
  outline: none !important;
  border-radius: 0.15rem;
  border: 2px solid var(--color1-m-14);
  box-shadow: 0 0 4px var(--color1-m-10);
}

.contact-form-block-email-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  /* row-gap: 0.2rem; */
}

.contact-form-block-message-container {
  display: grid;
  row-gap: 0.2rem;
}

.contact-form-block-message-text-area {
  height: 8rem;
  font-size: 1rem;
  padding: 0.55rem;
  resize: none;
  font-weight: 600;
  color: var(--color1-m-18);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.contact-form-block-message-text-area:focus {
  outline: none !important;
  border-radius: 0.15rem;
  border: 2px solid var(--color1-m-14);
  box-shadow: 0 0 4px var(--color1-m-10);
}

.contact-send-button-container {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 0.65rem;
  width: fit-content;
  align-items: center;
  color: var(--color1-m-2);
  background-color: var(--color1-m-18);
  border: 0.2rem solid var(--color1-m-6);
  border-radius: 0.25rem;
  padding: 0.65rem 0.75rem 0.65rem 1rem;
}

.contact-send-button-container:hover {
  cursor: pointer;
  filter: brightness(1.15);
}

.contact-send-button-text {
  font-size: 1rem;
  font-weight: 600;
}
.contact-send-button-img {
  width: 1.4rem;
}

/* Contact Info */
.contact-info-container {
  display: grid;
  padding: 3rem;
  background-color: var(--color1-h-1);
  width: 100%;
  /* grid-template-columns: 1fr 1fr; */
}

.contact-info-title {
  font-size: 1.75rem;
  /* text-transform: uppercase; */
  padding-bottom: 3rem;
  color: var(--color1-m-18);
  justify-self: center;
}

.contact-info-items-container {
  display: grid;
  /* row-gap: 3rem; */
  grid-template-columns: 1fr 1fr 1fr;
}

.contact-info-item {
  display: grid;
  /* row-gap: 1rem; */
  place-items: center;
}

.contact-info-item-img {
  width: 7rem;
}

.contact-info-item-title {
  font-size: 1.325rem;
  text-transform: uppercase;
  padding-top: 1rem;
  color: var(--color1-m-18);
}

.contact-info-item-sub-title {
  font-size: 0.96rem;
  text-transform: uppercase;
  padding-top: 2rem;
  color: var(--color1-m-18);
  font-weight: 700;
}

.contact-info-item-time {
  font-size: 0.7rem;
  /* text-transform: uppercase; */
  /* padding: 0.55rem 0; */
  padding-top: 0.5rem;
  color: var(--color1-m-18);
}

.contact-info-content {
  font-size: 0.91rem;
  line-height: 1.55rem;
  color: var(--color1-m-18);
  padding-top: 0.45rem;
  font-weight: 700;
}

/* Locate Us */

.contact-locate-container {
  display: grid;
  justify-items: center;
  padding: 3rem;
  background-color: var(--color1-h-2);
  width: 100%;
}

.contact-locate-title {
  font-size: 1.75rem;
  /* text-transform: uppercase; */
  padding-bottom: 3rem;
  color: var(--color1-m-18);
  justify-self: center;
}

.contact-locate-address {
  font-size: 0.96rem;
  /* text-transform: uppercase; */
  padding-bottom: 3rem;
  color: var(--color1-m-16);
  justify-self: center;
  font-weight: 700;
  width: 50%;
  text-align: center;
  line-height: 1.5rem;
}

.map-container {
  position: relative;
  display: grid;
  width: 95%;
  height: 84vh;
  justify-items: center;
}

.contact-map-frame {
  display: flex;
  /* max-height: 64rem; */
  border: 2px solid var(--color1-m-16);
  border-radius: 0.15rem;
}

.contact-open-map-container {
  /* display: flex; */
  /* background-color: var(--color1-m-16); */
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.open-in-map-text {
  text-decoration: none;
  color: var(--color1-h-2);
  background-color: var(--color1-m-16);
  border: 2px solid var(--color1-m-4);
  overflow: hidden;
  padding: 0.75rem 1rem;
  font-weight: 500;
  border-radius: 0.25rem;
  font-weight: 700;
  margin-top: 2rem;
}

.open-in-map-text:hover {
  color: var(--color1-h-1);
  border: 2px solid var(--color1-m-6);
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .contact-sub-title {
    width: 60%;
    text-align: center;
  }

  .contact-form-sub-container {
    width: 90%;
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .contact-map-frame {
    width: 100%;
    border: none;
  }

  .map-container {
    width: 100%;
    height: 60vh;
  }

  .open-in-map-text {
    font-size: 1.88rem;
    padding: 1.55rem;
  }
}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1.1em;
  }
} */
