:root {
  /* --primary_nice_orange: rgb(170, 76, 76); */
  /* --primary_main_bg: hsl(0, 0%, 93%);
  --primary_main_bg_dark: hsl(0, 0%, 90%);
  --primary_main_bg_darker: hsl(0, 0%, 87%); */

  /* --primary_main_bg: hsl(51, 9%, 43%);
  --primary_main_bg_dark: hsl(51, 10%, 40%);
  --primary_main_bg_darker: hsl(51, 10%, 38%); */

  /* --primary_main_bg: #f8f9fa;
  --primary_main_bg_dark: hsl(0, 0%, 30%);
  --primary_main_bg_darker: hsl(0, 0%, 10%);
  --primary_main_bg_footer: hsl(0, 0%, 15%);

  --primary_white: #ffffff;
  --primary_white_dark: #ccc;
  --primary_white_darker: hsl(225, 100%, 77%);

  --primary_blue_darker: hsl(226, 71%, 22%);
  --primary_blue_dark: hsl(225, 88%, 44%);
  --primary_blue_normal: hsl(230, 80%, 45%);
  --primary_blue_soft: hsl(225, 98%, 68%);
  --primary_blue_light: hsl(225, 100%, 77%);
  --primary_blue_lighter: hsl(225, 90%, 92%);

  --primary_green_darker: hsl(135, 73%, 18%);
  --primary_green_dark: hsl(135, 64%, 41%);
  --primary_green_normal: hsl(135, 65%, 45%);
  --primary_green_soft: hsl(135, 89%, 60%);
  --primary_green_light: hsl(135, 85%, 75%);
  --primary_green_lighter: hsl(135, 90%, 90%);

  --primary_red_darker: hsl(0, 73%, 19%);
  --primary_red_dark: hsl(351, 64%, 47%);
  --primary_red_normal: hsl(355, 100%, 61%);
  --primary_red_soft: hsl(0, 100%, 75%);
  --primary_red_light: hsl(0, 100%, 86%);
  --primary_red_lighter: hsl(0, 100%, 93%); */

  /* new colors */
  /* --color-section-bg-primary: #f8f9fa;
  --color-section-bg-secondary: hsl(180, 50%, 90%); */

  /* Optional Colors */
  --color-transparent-bg-1: hsla(205, 80%, 35%, 0.7);

  /* Low Saturated Colors */
  --color1-l-1: hsl(205, 25%, 96%);
  --color1-l-2: hsl(205, 25%, 92%);
  --color1-l-3: hsl(205, 25%, 88%);
  --color1-l-4: hsl(205, 25%, 84%);
  --color1-l-5: hsl(205, 25%, 80%);
  --color1-l-6: hsl(205, 25%, 76%);
  --color1-l-7: hsl(205, 25%, 72%);
  --color1-l-8: hsl(205, 25%, 68%);
  --color1-l-9: hsl(205, 25%, 64%);
  --color1-l-10: hsl(205, 25%, 60%);
  --color1-l-11: hsl(205, 25%, 56%);
  --color1-l-12: hsl(205, 25%, 52%);
  --color1-l-13: hsl(205, 25%, 48%);
  --color1-l-14: hsl(205, 25%, 44%);
  --color1-l-15: hsl(205, 25%, 40%);
  --color1-l-16: hsl(205, 25%, 36%);
  --color1-l-17: hsl(205, 25%, 32%);
  --color1-l-18: hsl(205, 25%, 28%);
  --color1-l-19: hsl(205, 25%, 24%);
  --color1-l-20: hsl(205, 25%, 20%);
  --color1-l-21: hsl(205, 25%, 20%);
  --color1-l-22: hsl(205, 25%, 16%);
  --color1-l-23: hsl(205, 25%, 12%);
  --color1-l-24: hsl(205, 25%, 8%);
  --color1-l-25: hsl(205, 25%, 4%);

  /* Medium Saturated Colors */
  --color1-m-1: hsl(205, 50%, 96%);
  --color1-m-2: hsl(205, 50%, 92%);
  --color1-m-3: hsl(205, 50%, 88%);
  --color1-m-4: hsl(205, 50%, 84%);
  --color1-m-5: hsl(205, 50%, 80%);
  --color1-m-6: hsl(205, 50%, 76%);
  --color1-m-7: hsl(205, 50%, 72%);
  --color1-m-8: hsl(205, 50%, 68%);
  --color1-m-9: hsl(205, 50%, 64%);
  --color1-m-10: hsl(205, 50%, 60%);
  --color1-m-11: hsl(205, 50%, 56%);
  --color1-m-12: hsl(205, 50%, 52%);
  --color1-m-13: hsl(205, 50%, 48%);
  --color1-m-14: hsl(205, 50%, 44%);
  --color1-m-15: hsl(205, 50%, 40%);
  --color1-m-16: hsl(205, 50%, 36%);
  --color1-m-17: hsl(205, 50%, 32%);
  --color1-m-18: hsl(205, 50%, 28%);
  --color1-m-19: hsl(205, 50%, 24%);
  --color1-m-20: hsl(205, 50%, 20%);
  --color1-m-21: hsl(205, 50%, 20%);
  --color1-m-22: hsl(205, 50%, 16%);
  --color1-m-23: hsl(205, 50%, 12%);
  --color1-m-24: hsl(205, 50%, 8%);
  --color1-m-25: hsl(205, 50%, 4%);

  /* High Saturated Colors */
  --color1-h-1: hsl(205, 75%, 96%);
  --color1-h-2: hsl(205, 75%, 92%);
  --color1-h-3: hsl(205, 75%, 88%);
  --color1-h-4: hsl(205, 75%, 84%);
  --color1-h-5: hsl(205, 75%, 80%);
  --color1-h-6: hsl(205, 75%, 76%);
  --color1-h-7: hsl(205, 75%, 72%);
  --color1-h-8: hsl(205, 75%, 68%);
  --color1-h-9: hsl(205, 75%, 64%);
  --color1-h-10: hsl(205, 75%, 60%);
  --color1-h-11: hsl(205, 75%, 56%);
  --color1-h-12: hsl(205, 75%, 52%);
  --color1-h-13: hsl(205, 75%, 48%);
  --color1-h-14: hsl(205, 75%, 44%);
  --color1-h-15: hsl(205, 75%, 40%);
  --color1-h-16: hsl(205, 75%, 36%);
  --color1-h-17: hsl(205, 75%, 32%);
  --color1-h-18: hsl(205, 75%, 28%);
  --color1-h-19: hsl(205, 75%, 24%);
  --color1-h-20: hsl(205, 75%, 20%);
  --color1-h-21: hsl(205, 75%, 20%);
  --color1-h-22: hsl(205, 75%, 16%);
  --color1-h-23: hsl(205, 75%, 12%);
  --color1-h-24: hsl(205, 75%, 8%);
  --color1-h-25: hsl(205, 75%, 4%);

  /* Very High Saturated Colors */
  --color1-vh-1: hsl(205, 100%, 96%);
  --color1-vh-2: hsl(205, 100%, 92%);
  --color1-vh-3: hsl(205, 100%, 88%);
  --color1-vh-4: hsl(205, 100%, 84%);
  --color1-vh-5: hsl(205, 100%, 80%);
  --color1-vh-6: hsl(205, 100%, 76%);
  --color1-vh-7: hsl(205, 100%, 72%);
  --color1-vh-8: hsl(205, 100%, 68%);
  --color1-vh-9: hsl(205, 100%, 64%);
  --color1-vh-10: hsl(205, 100%, 60%);
  --color1-vh-11: hsl(205, 100%, 56%);
  --color1-vh-12: hsl(205, 100%, 52%);
  --color1-vh-13: hsl(205, 100%, 48%);
  --color1-vh-14: hsl(205, 100%, 44%);
  --color1-vh-15: hsl(205, 100%, 40%);
  --color1-vh-16: hsl(205, 100%, 36%);
  --color1-vh-17: hsl(205, 100%, 32%);
  --color1-vh-18: hsl(205, 100%, 28%);
  --color1-vh-19: hsl(205, 100%, 24%);
  --color1-vh-20: hsl(205, 100%, 20%);
  --color1-vh-21: hsl(205, 100%, 20%);
  --color1-vh-22: hsl(205, 100%, 16%);
  --color1-vh-23: hsl(205, 100%, 12%);
  --color1-vh-24: hsl(205, 100%, 8%);
  --color1-vh-25: hsl(205, 100%, 4%);

  /* Black White Colors */
  --color-bw-1: hsl(0, 0%, 96%);
  --color-bw-2: hsl(0, 0%, 92%);
  --color-bw-3: hsl(0, 0%, 88%);
  --color-bw-4: hsl(0, 0%, 84%);
  --color-bw-5: hsl(0, 0%, 80%);
  --color-bw-6: hsl(0, 0%, 76%);
  --color-bw-7: hsl(0, 0%, 72%);
  --color-bw-8: hsl(0, 0%, 68%);
  --color-bw-9: hsl(0, 0%, 64%);
  --color-bw-10: hsl(0, 0%, 60%);
  --color-bw-11: hsl(0, 0%, 56%);
  --color-bw-12: hsl(0, 0%, 52%);
  --color-bw-13: hsl(0, 0%, 48%);
  --color-bw-14: hsl(0, 0%, 44%);
  --color-bw-15: hsl(0, 0%, 40%);
  --color-bw-16: hsl(0, 0%, 36%);
  --color-bw-17: hsl(0, 0%, 32%);
  --color-bw-18: hsl(0, 0%, 28%);
  --color-bw-19: hsl(0, 0%, 24%);
  --color-bw-20: hsl(0, 0%, 20%);
  --color-bw-21: hsl(0, 0%, 20%);
  --color-bw-22: hsl(0, 0%, 16%);
  --color-bw-23: hsl(0, 0%, 12%);
  --color-bw-24: hsl(0, 0%, 8%);
  --color-bw-25: hsl(0, 0%, 4%);

  /* --color-font-title-0: #000;
  --color-font-title-1: #111;
  --color-font-title-2: #222;
  --color-font-title-3: #333;
  --color-font-title-4: #444;
  --color-font-title-5: #555;
  --color-font-title-6: #666;
  --color-font-title-7: #777;
  --color-font-title-8: #888;
  --color-font-title-9: #999;
  --color-font-title-a: #aaa;
  --color-font-title-b: #bbb;
  --color-font-title-c: #ccc;
  --color-font-title-d: #ddd;
  --color-font-title-e: #eee;
  --color-font-title-f: #fff;

  --color-font-text-0: #000;
  --color-font-text-1: #111;
  --color-font-text-2: #222;
  --color-font-text-3: #333;
  --color-font-text-4: #444;
  --color-font-text-5: #555;
  --color-font-text-6: #666;
  --color-font-text-7: #777;
  --color-font-text-8: #888;
  --color-font-text-9: #999;
  --color-font-text-a: #aaa;
  --color-font-text-b: #bbb;
  --color-font-text-c: #ccc;
  --color-font-text-d: #ddd;
  --color-font-text-e: #eee;
  --color-font-text-f: #fff;

  --color-btn-bg-primary: hsl(221, 63%, 35%);
  --color-btn-bg-primary-light: hsl(221, 63%, 40%);
  --color-btn-bg-heading: hsl(0, 0%, 93%);
  --color-transparent-ui-bg: hsla(0, 0%, 65%, 0.7);
  --color-transparent-ui-border: hsla(221, 63%, 35%, 0.8); */
}

.filter-svg {
  filter: invert(23%) sepia(73%) saturate(399%) hue-rotate(163deg)
    brightness(99%) contrast(95%);
}

.filter-svg-2 {
  /* --color1-m-8: #85b4d6; */
  /* --color1-m-4: #c2daeb; */
  filter: invert(23%) sepia(73%) saturate(90%) hue-rotate(163deg)
    brightness(99%) contrast(95%);
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 1rem;
}

body {
  position: relative;
  background-color: var(--color1-24);
  color: var(--color1-3);
  /* background-color: black;
  color: white; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

.web-pages-main-container {
  display: grid;
  position: relative;
  /* font-family: "Raleway", sans-serif; */
  /* padding-top: 8.5rem; */
  justify-items: center;
  width: 100%;
  /* max-width: 100vw; */
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  padding: 0;
}

/* Common styles */

/* bg blue */
.bg-blue-darker {
  background-color: var(--primary_blue_darker);
}

.bg-blue-dark {
  background-color: var(--primary_blue_dark);
}

.bg-blue-normal {
  background-color: var(--primary_blue_normal);
}

.bg-blue-soft {
  background-color: var(--primary_blue_soft);
}

.bg-blue-light {
  background-color: var(--primary_blue_light);
}

.bg-blue-lighter {
  background-color: var(--primary_blue_lighter);
}

/* bg green */
.bg-green-darker {
  background-color: var(--primary_green_darker);
}

.bg-green-dark {
  background-color: var(--primary_green_dark);
}

.bg-green-normal {
  background-color: var(--primary_green_normal);
}

.bg-green-soft {
  background-color: var(--primary_green_soft);
}

.bg-green-light {
  background-color: var(--primary_green_light);
}

.bg-green-lighter {
  background-color: var(--primary_green_lighter);
}

/* bg red */
.bg-red-darker {
  background-color: var(--primary_red_darker);
}

.bg-red-dark {
  background-color: var(--primary_red_dark);
}

.bg-red-normal {
  background-color: var(--primary_red_normal);
}

.bg-red-soft {
  background-color: var(--primary_red_soft);
}

.bg-red-light {
  background-color: var(--primary__light);
}

.bg-red-lighter {
  background-color: var(--primary_red_lighter);
}

/* border-color */
.border-color-red-light {
  border-color: var(--primary_red_light);
}

.border-color-red-soft {
  border-color: var(--primary_red_soft);
}

.border-color-red-normal {
  border-color: var(--primary_red_normal);
}

.border-color-red-dark {
  border-color: var(--primary_red_dark);
}

/* border-blue */
.border-color-blue-light {
  border-color: var(--primary_blue_light);
}

.border-color-blue-soft {
  border-color: var(--primary_blue_soft);
}

.border-color-blue-normal {
  border-color: var(--primary_blue_normal);
}

.border-color-blue-dark {
  border-color: var(--primary_blue_dark);
}

/* border-green */
.border-color-green-light {
  border-color: var(--primary_green_light);
}

.border-color-green-soft {
  border-color: var(--primary_green_soft);
}

.border-color-green-normal {
  border-color: var(--primary_green_normal);
}

.border-color-green-dark {
  border-color: var(--primary_green_dark);
}

/* grid-column  */
.grid-col-1 {
  grid-template-columns: 1fr;
}

.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-col-6 {
  grid-template-columns: repeat(6, 1fr);
}

.common-layout-container {
  display: grid;
  /* grid-template-columns: 1fr; */
  /* justify-items: flex-start; */
  /* min-height: 100vh; */
  /* width: 99vw; */
  /* justify-content: space-between; */
  /* flex-direction: column; */
  /* align-items: center; */
}

.border-left {
  border-left: 1px solid #aaa;
}


/* ########## Dropdown Styles ############## */

.dropdown-wrap-width {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0.5rem;
}

.dropdown-full-width {
  width: 100%;
  display: grid;
  /* grid-template-columns: 1.2fr 1.75fr; */
  height: fit-content;
  /* column-gap: 0.5rem; */
  /* align-items: center; */
  justify-items: center;
  align-self: center;
  justify-self: center;
  /* margin-bottom: 0.5rem; */
}

.dropdown-label {
  font-size: 0.96rem;
  min-width: 3rem;
}

.dropdown-main {
  width: 90%;
  /* min-width: 10rem; */
  position: relative;
  /* display: flex; */
  /* place-items: center; */
  /* justify-content: center;
  justify-items: center; */
}

.dropdown-main-full {
  width: 100%;
  /* min-width: 10rem; */
  position: relative;
  /* display: flex; */
  /* place-items: center; */
  /* justify-content: center;
  justify-items: center; */
}

.dropdown-btn {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--color1-m-2);
  border: 1px solid var(--color1-m-18);
  color: var(--color1-m-18);
  padding: 0.3rem;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 0.15rem;
  cursor: pointer;
  height: 1.75rem;
  /* align-self: center;
  justify-self: center; */
}

.drop-icon {
  position: absolute;
  right: 0;
  width: 0.95rem;
  margin-right: 0.5rem;
  transition: transform 0.45s ease-in-out;
}

.dropdown-main:hover .drop-icon {
  transform: rotateX(180deg);
}

.dropdown-content {
  width: 100%;
  display: none;
  position: absolute;
  background-color: var(--color1-m-1);
  box-shadow: 0px 0.5rem 1rem 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  max-height: 9.25rem;
  overflow-y: scroll;
  border-left: 1px solid var(--color1-l-10);
  border-right: 1px solid var(--color1-l-10);
  /* z-index: 10000; */
  /* border-radius: 0.3rem; */
  /* overflow: hidden; */
  /* margin-top: 0.05rem; */
  /* font-weight: 600; */
}

.dropdown-item {
  display: flex;
  color: var(--color1-m-18);
  padding-left: 0.3rem;
  font-size: 0.78rem;
  min-height: 1.9rem;
  align-items: center;
  font-weight: 600;
  border-bottom: 1px solid var(--color1-l-10);
}

.dropdown-item:hover {
  background-color: var(--color1-m-3);
  cursor: pointer;
}

.dropdown-main:hover .dropdown-content {
  display: block;
}
