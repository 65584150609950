/* ImageGallery.css */
.image-gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 18px;
    margin: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .image-container {
    flex-grow: 1;
    align-items: center;
    display: grid;
    height: 100vh;
    width: 100vw;
    justify-items: center;
  }
  
  .image-container img {
    max-width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: hsla(0, 0%, 0%, 0.55);
    border-radius: 1rem;
  }
  
  .prev-button,
  .next-button {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 18px;
    padding: 10px;
  }
  
  .index {
    font-size: 16px;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }
  
  .left-arrow {
    width: 2.8rem;
    object-fit: cover;
    left: 3rem;
  }
  
  .right-arrow {
    width: 2.8rem;
    object-fit: cover;
    right: 3rem;
  }
  
  .arrow:hover {
    opacity: 1;
  }

  /* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .image-container img {
    width: 100vw;
    height: auto;
  }

}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}