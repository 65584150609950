.gallery-main-container {
  display: grid;
  padding-bottom: 4rem;
  /* row-gap: 3rem; */
}
.gallery-item-container {
  display: grid;
  row-gap: 3rem;
}

.gallery-item-title {
  font-size: 2rem;
  font-weight: bold;
  justify-self: center;
  padding-top: 3rem;
  color: var(--color1-m-18);
  user-select: none;
}

.gallery-images-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
  padding: 0 4rem;
}

.gallery-img {
  width: 100%;
  /* aspect-ratio: 7/5; */
  aspect-ratio: 3/2;
  /* height: 22.5rem; */
  object-fit: cover;
  border: 0.186rem solid var(--color1-m-12);


}

.gallery-img:hover {
  cursor: pointer;
}

.gallery-magnify-icon {
  width: 5.5rem;
  /* aspect-ratio: 1/1; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  /* z-index: 10; */
  cursor: pointer;
}

.rooms-img-item-container:hover .gallery-img {
  filter: brightness(1.1);
}

.rooms-img-item-container:hover .gallery-magnify-icon {
  opacity: 1;
  /* background-color: aqua; */
  z-index: 100;
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .gallery-img {
    aspect-ratio: 1/1;
  }

}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1.1em;
  }
} */
