.about-main-container {
  display: grid;
  width: 100%;
  justify-items: center;
  justify-self: center;
}

.about-hero-img {
  width: 100%;
  height: 36rem;
  object-fit: cover;
  /* padding-top: 4rem; */
}

.about-info-container {
  display: grid;
  padding-bottom: 4rem;
  row-gap: 3rem;
  padding-top: 1rem;
  background-color: var(--color1-h-1);
}

.about-info-item {
  display: grid;
  justify-items: center;
  width: 70%;
  justify-self: center;
  background-color: var(--color1-h-2);

  /* row-gap: 1rem; */
}

.about-info-item-content-container {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 3rem;
  padding-bottom: 3rem;
  width: 90%;
}

.about-info-item-img {
  width: 16rem;
  object-fit: cover;
  border: 2px solid var(--color1-m-8);
}

.about-info-item-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--color1-m-20);
  /* justify-self: left; */
  padding-top: 3rem;
  /* margin-left: 20%; */
}

.about-info-item-text {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* align-items: center; */
  /* width: 60%; */
  padding: 3rem 0;
  row-gap: 1rem;
  color: var(--color1-m-14);
  text-align: justify;
  line-height: 1.75rem;
  font-size: 1.04rem;

  /* justify-items: left; */
}

/* .about-bg-light {
  background-color: var(--color1-h-1);
}
.about-bg-dark {
  background-color: var(--color1-h-2);
} */

.about-board-of-directors-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3rem 4rem;
  user-select: none;
  column-gap: 3rem;
  color: var(--color1-m-20);
}

.about-person-item {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 3.5rem;
  background-color: var(--color1-h-4);
  border-radius: 0.5rem;
  row-gap: 0.8rem;
}

.about-person-item:hover img {
  /* filter: none; */
  filter: grayscale(1);

  transition: ease 0.65s;
}

.about-person-item:hover {
  background-color: var(--color1-h-5);
  transition: ease 0.3s;
}

.about-img-item {
  display: flex;
  justify-content: stretch;
  border: 0.3rem solid var(--color1-m-15);
  border-radius: 50%;
  overflow: hidden;
  width: 11.5rem;
  height: 11.5rem;
  margin: 0 auto 0 auto;
}

.about-director-img {
  width: 11.25rem;
  height: 11.25rem;
  /* filter: grayscale(1); */
  object-fit: cover;

}

.about-person-item-name {
  font-size: 1.15rem;
  text-align: center;
  margin: 1rem 0 0 0;
}

.about-person-item-title {
  font-size: 1.05rem;
  font-weight: 600;
  text-align: center;
  text-orientation: sideways-right;
}

.about-person-item-mesage {
  font-size: 0.99rem;
  line-height: 1.75rem;
  margin: 0.5rem 0 0 0;
  text-align: justify;
  user-select: none;
  font-weight: 600;
  color: var(--color1-m-16);
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .about-info-item {
    width: 90%;
  }

  .about-person-item-mesage {
    line-height: 1.4rem;
  }

  .about-person-item {
    /* display: grid;
    justify-content: center;
    align-items: center; */
    padding: 2.75rem;
    /* background-color: var(--color1-h-4);
    border-radius: 0.5rem;
    row-gap: 0.8rem; */
  }
}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1.1em;
  }
} */
