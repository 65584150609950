.faq-main-container {
  display: grid;
  width: 100%;
  /* width: 65%; */
  color: var(--color1-h-3);
  background-color: hsl(205, 75%, 80%);
  justify-items: center;
  /* justify-self: center; */
}

.faq-title {
  font-size: 2.15rem;
  text-transform: uppercase;
  /* width: 65%; */
  padding-top: 3rem;
  color: var(--color1-h-18);
  font-weight: 600;
  justify-self: center;
}

.faq-item-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* row-gap: 1rem; */
  column-gap: 3rem;
  width: 65%;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.faq-col {
  display: grid;
  row-gap: 1.6rem;
  align-self: flex-start;
}

.faq-item {
  background-color: var(--color1-m-12);
  padding: 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  user-select: none;
}

.faq-item-question-continer {
  display: grid;
  grid-template-columns: auto 10fr;
  column-gap: 0.9rem;
  align-items: center;
}

.faq-item-question-icon {
  width: 1.65rem;
  object-fit: cover;
}

.faq-item-question-text {
  font-size: 0.95rem;
  font-weight: 600;
}

.faq-item-answer-text {
  padding: 1rem 2.65rem;
  font-size: 0.95rem;
  text-align: justify;
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .faq-item-container {
    width: 90%;
  }

}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1.1em;
  }
} */
