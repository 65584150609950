.alert-main-container {
  display: grid;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  z-index: 1010;
  top: 0;
  background-color: hsla(0, 0%, 0%, 0.75);
}

.alert-z-forward {
  z-index: 1050;
}

.alert-dialog-container {
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr;
  border-radius: 0.75rem;
  overflow: hidden;
  justify-items: center;
  align-items: center;
  min-width: 28rem;
  max-width: 29rem;
  min-height: 26rem;
  box-shadow: 0.25rem 0.35rem 0.55rem #333;
}

.alert-header {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
  row-gap: 0.5rem;
}

.alert-img {
  max-height: 4rem;
  align-self: flex-end;
}

.alert-title {
  align-self: flex-start;
  font-size: 1.15rem;
  text-transform: uppercase;
  color: white;
}

.alert-body {
  position: relative;
  box-sizing: border-box;
  display: grid;
  height: 100%;
  background-color: white;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.alert-msg {
  text-align: justify;
  color: #6f6f6f;
  font-size: 1.05rem;
  line-height: 1.4rem;
  white-space: pre-wrap;
  max-width: 80%;
}

.alert-btn {
  width: 40%;
  border-radius: 12em;
  border: none;
  outline-color: #d5a021;
  padding: 0.85rem 0;
  font-weight: 500;
  font-size: 1.125rem;
  cursor: pointer;
  color: white;
  box-shadow: 1px 0.25rem 1.5rem hsl(0, 0%, 75%);
}

.alert-btn:focus {
  outline: 2px solid hsl(0, 0%, 80%);
}

.alert-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-items: center;
}

.confirm-btn {
  width: 75%;
}

/* colors */

.alert-bg-green {
  background-color: rgb(139, 195, 74);
}
.alert-bg-red {
  background-color: rgb(195, 88, 74);
}
.alert-bg-yellow {
  background-color: rgb(195, 167, 74);
}
.alert-bg-blue {
  background-color: rgb(74, 122, 195);
}

/* Loading Alert */

.loading-alert-container {
  display: grid;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-content: center;
  background-color: hsla(0, 0%, 0%, 0.65);
  z-index: 1100;
}

.fixed-alert-container {
  display: grid;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-content: center;
  background-color: rgb(210, 237, 252);
  z-index: 1000;
}

.auth-inside-container {
  display: grid;
  width: 30%;
  background-color: var(--color1-m-15);
  padding: 3rem;
  border-radius: 0.5rem;
}

.auth-btn-2 {
  display: grid;
  align-self: center;
  justify-self: center;
  margin-top: 2rem;
  padding: 1rem 2rem;
  border-radius: 0.35rem;
  background-color: var(--color1-m-18);
  border: 2px solid white;
  font-size: 0.92rem;

}

.auth-btn-2:hover {
  background-color: var(--color1-m-17);


}

.auth-title {
  font-size: 1.1rem;
  color: var(--color1-m-1);
  font-weight: 600;
  user-select: none;
  padding-bottom: 0.15rem;
}

.loading-alert-lds-ripple {
  display: inline-block;
  position: relative;
  width: 7rem;
  height: 7rem;
}

.loading-alert-lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading-alert-lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading-alert-lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes loading-alert-lds-ripple {
  0% {
    top: 3rem;
    left: 3rem;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 3rem;
    left: 3rem;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 3rem;
    left: 3rem;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 6rem;
    height: 6rem;
    opacity: 0;
  }
}

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .alert-dialog-container {
    width: 75%;
    max-width: 90%;
    aspect-ratio: 1/1;
    min-height: max-content;
  }

  .alert-img {
    max-height: 9rem;
  }

  .alert-title {
    /* align-self: flex-start; */
    font-size: 2.5rem;
    padding-top: 2rem;
    /* text-transform: uppercase; */
    /* color: white; */
  }

  .alert-msg {
    font-size: 2rem;
    line-height: 2rem;
  }

  .alert-btn {
    font-size: 2.3rem;
    /* width: 30%; */
    width: max-content;
    padding: 1.4rem 7rem;
    /* box-shadow: 1px 0.25rem 1.5rem hsl(0, 0%, 75%); */
  }
}
