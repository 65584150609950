/* UI Container */
.margin-top-0-25 {
  margin-top: 0.25rem;
}
.margin-top-0-5 {
  margin-top: 0.5rem;
}
.margin-top-0-75 {
  margin-top: 0.75rem;
}
.margin-top-1 {
  margin-top: 1rem;
}

.display-grid {
  display: grid;
}

.display-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-left {
  justify-items: left;
}

.justify-items-right {
  justify-items: right;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-left {
  justify-self: left;
}

.justify-self-right {
  justify-self: right;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

.no-border {
  border: none;
}

.no-outline {
  outline: none;
}

/* Font */
.font-size-0-9 {
  font-size: 0.9rem;
}
.font-size-1 {
  font-size: 1rem;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.color-transparent {
  color: transparent;
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

/* Background */
.bg-transparent {
  background-color: transparent;
}

.bg-black {
  background-color: black;
}

.bg-white {
  background-color: white;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0.5rem 0;
  width: 1rem;
  height: max-content;
  /* margin: auto 0; */
  /* aspect-ratio: 1/1; */
  /* padding-top: 1rem; */
}

.react-datepicker__view-calendar-icon input {
  padding: 0 0 0 1.5rem;
  align-items: center;
  display: flex;
  user-select: none;
  /* cursor: not-allowed; */
}

/* .date-picker-item{
    user-select: none;
  } */

.date-picker-item {
  width: 100%;
  height: 2.2rem;
  font-size: 0.71rem;
  cursor: pointer;
  padding: 0.15rem 0;
  /* padding-left: 1rem; */
  margin: 0;
  user-select: none;

  border: none;
  outline: none;
  color: var(--color1-m-18);
  font-weight: 600;
  /* border: 1px solid var(--color1-m-17); */
  /* border: none; */
  padding: 0;
}