.hero-main {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}

.hero-img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Booking-bar */
.book-bar-container {
  /* min-width: 69rem; */
  position: absolute;
  display: grid;
  background-color: var(--color1-m-4);
  border: 0.35rem solid var(--color1-m-14);
  align-self: end;
  margin-bottom: 3rem;
  grid-template-columns: max-content max-content max-content max-content;
  align-items: center;
  column-gap: 1rem;
  border-radius: 0.15rem;
  padding: 0.5rem;
}

.book-bar-item {
  width: 100%;
  display: grid;
  grid-template-columns: max-content 12rem;
  height: fit-content;
  padding: 0 0;
  /* height: 2rem; */
  column-gap: 0.5rem;
  align-items: center;
  border: 1px solid var(--color1-m-12);
  background-color: white;
}

.book-bar-item-lable {
  width: 100%;
  text-align: right;
  font-size: 0.85rem;
  font-weight: 700;
  color: var(--color1-h-19);
  padding-left: 0.5rem;
}

/* .book-bar-item-input {
  height: 100%;
  width: 100%;
  padding: 0.38rem;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  resize: none;
  font-size: 0.91rem;
  outline-style: none;
  border: 1px solid hsl(0, 0%, 55%);
  border-radius: 0.1rem;
} */

/* .book-bar-item-input:focus {
  border-color: hsl(214, 100%, 58%);
} */

.book-btn {
  height: max-content;
  min-height: 2rem;
  width: max-content;
  padding: 0.72rem 0.85rem;
  font-size: 0.88rem;
  background-color: var(--color1-vh-19);
  color: var(--color1-vh-2);
  border-radius: 0.2rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
}

.book-btn-2 {
  display: grid;
  /* justify-self: center; */
  align-self: center;
  /* position: absolute;
  bottom: 1rem; */
  margin-top: 2.25rem;
  margin-bottom: 1rem;
  padding: 0.76rem 0.8rem;
  /* height: max-content;
  min-height: 2rem;
  width: max-content;
  padding: 0.72rem 0.85rem;
  font-size: 0.88rem;
  background-color: var(--color1-vh-19);
  color: var(--color1-vh-2);
  border-radius: 0.2rem;
  font-weight: 600;
  cursor: pointer;
  border: none; */
}

.book-btn:hover {
  background-color: var(--color1-vh-17);
  color: var(--color1-vh-1);
}

.book-bar-total-text {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 0.86rem;
}

/* Date Picker */
.date-picker-container {
  width: 100%;
  height: 100%;
  display: grid;
  cursor: pointer;
  user-select: none;
  /* padding-left: 1rem; */
}

/* .react-datepicker__input-container {
  display: grid;
  align-items: center;
  padding: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
} */

.book-bar-date-picker-main-container{
  padding-left: 0.5rem;
  background-color: white;
  border: 1px solid var(--color1-m-17);

}

/* book-bar */
.book-bar-room-container {
  position: relative;
  width: 18.5rem;
  display: grid;
  grid-template-columns: max-content 4fr;
  column-gap: 0.4rem;
  align-items: center;
  background-color: white;
  padding: 0.15rem 0;
  border: 1px solid var(--color1-m-12);

}

.book-bar-icon {
  width: 1.5rem;
  padding: 0.1rem;
  margin-left: 0.2rem;
}

.book-bar-room-input {
  min-height: 2rem;
  width: 100%;
  border: none;
  outline: none;
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--color1-m-18);
}

.alert-main-container {
  display: grid;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  z-index: 1010;
  top: 0;
  background-color: hsla(0, 0%, 0%, 0.75);
}

/* book-bar-popup-ui */
.book-bar-popup-main-container {
  display: grid;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  color: var(--color1-m-18);
  background-color: hsla(0, 0%, 0%, 0.5);
}

.book-bar-popup-ui-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* grid-template-rows: 1fr 1fr; */
  border-radius: 0.75rem;
  overflow: hidden;
  /* align-items: center; */
  /* justify-content: center; */
  /* justify-items: center; */
  width: 40%;
  /* aspect-ratio: 10/9; */
  /* min-height: 37vw; */
  height: max-content;
  /* min-width: 28rem;
  max-width: 29rem;
  min-height: 26rem; */
  box-shadow: 0.25rem 0.35rem 0.55rem #333;
  background-color: var(--color1-h-2);
}

.bookbar-title {
  font-size: 1.35rem;
  font-weight: bold;
  padding-top: 1rem;
  color: var(--color1-m-20);
  align-self: center;
  /* border-bottom: 1px solid var(--color1-l-14); */
  /* padding-bottom: 0.75rem; */
}

.bookbar-booking-sub-title {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 1.25rem;
  padding-bottom: 0.55rem;
  color: var(--color1-m-20);
  border-bottom: 1px solid var(--color1-l-14);
  display: grid;
  /* width: max-content; */
  align-self: center;
  width: 90%;
  /* text-align: center; */
  margin-left: 4.5%;
  margin-right: 4.5%;
}

.bookbar-booking-sub-title-no-border {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 1.5rem;
  /* padding-bottom: 0.55rem; */
  color: var(--color1-m-20);
  /* border-bottom: 1px solid var(--color1-l-14); */
  display: grid;
  align-self: center;
  width: 90%;
  text-align: center;
  margin-left: 4.5%;
  margin-right: 4.5%;
}

.bookbar-close-img {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.25rem;
  z-index: 100000;
  cursor: pointer;
}

.book-bar-name-container {
  /* position: relative; */
  display: grid;
  width: 100%;
  /* justify-content: center;
  padding: 0.75rem; */
  grid-template-columns: 1fr 3fr 3fr;
  column-gap: 2rem;
  /* align-items: center; */
  padding: 0 3rem;
  padding-top: 0.75rem;
}

.book-bar-email-phone-container {
  /* position: relative; */
  display: grid;
  width: 100%;
  /* justify-content: center;
  padding: 0.75rem; */
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  /* align-items: center; */
  padding: 0.751rem 3rem 0 3rem;
  /* padding-top: 0.5rem; */
}

.book-bar-date-container {
  /* position: relative; */
  display: grid;
  width: 100%;

  /* justify-content: center;
  padding: 0.75rem; */
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  /* align-items: center; */
  padding: 0 3rem;
  padding-top: 0.75rem;

  /* padding-top: 1.5rem; */
}

.book-bar-name-item {
  display: grid;
  /* grid-template-columns: max-content auto; */
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
}

/* .book-bar-name-title-container {
  width: 100%;
  display: grid;
} */

.book-bar-name-lable {
  font-size: 0.9rem;
  color: var(--color1-m-17);
  font-weight: 600;
  user-select: none;
  padding-bottom: 0.15rem;
}

.book-bar-name-input {
  height: 2.2rem;
  padding-left: 0.55rem;
  font-size: 0.83rem;
  font-weight: 600;
  color: var(--color1-m-17);
  border: 1px solid var(--color1-m-17);
  /* align-items: center; */
}

.book-bar-name-input:focus {
  border: none;
  outline: 2px solid var(--color1-m-17);
}

.book-bar-popup-head {
  display: grid;
  font-weight: bold;
  background-color: var(--color1-m-2);
  user-select: none;
  align-items: center;
  width: 100%;
  height: max-content;
  justify-items: center;
  border-top: 1px solid var(--color1-m-15);
  border-bottom: 1px solid var(--color1-m-15);
  margin-top: 1rem;
}

.book-bar-popup-columns {
  width: 100%;
  grid-template-columns: 0.4fr 1.5fr 1fr 1fr 0.8fr 0.4fr;
}

.book-bar-popup-head-text {
  width: 100%;
  height: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color1-m-18);
  font-weight: 700;
  font-size: 0.76rem;
}

.book-bar-rooms-list-main-container {
  overflow: scroll;
  max-height: 30vh;
  /* border-bottom: 1px solid var(--color1-m-14); */
  /* overflow-x: hidden; */
  /* padding-bottom: 0.5rem; */
  /* height: 10rem; */
  /* border: 2px solid black; */
  /* display: flex; */
}

.book-bar-room-list-container {
  display: grid;
  border-bottom: 1px solid var(--color1-l-10);
  padding: 0.28rem 0;
  /* align-items: flex-start; */
}

.dropdown-room-btn {
  /* width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--color1-m-2);
  border: 1px solid var(--color1-m-18);
  color: var(--color1-m-18);
  padding: 0.4rem;
  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 0.15rem;
  cursor: pointer;
  height: 1.75rem; */
  /* align-self: center;
  justify-self: center; */
  height: 2.1rem;
  font-size: 0.72rem;
}

.book-bar-popup-input-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  border: 1px solid var(--color1-m-18);
  background-color: var(--color1-m-2);
  width: 5rem;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 1.75rem;
  align-self: center;
  justify-self: center;
}

.book-bar-popup-input-img {
  height: 0.95rem;
  object-fit: cover;
  cursor: pointer;
  justify-self: center;
  align-self: center;
}

.book-bar-popup-input-img:hover {
  filter: invert(0.25);
}

.book-bar-popup-list-input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  text-align: right;
  padding-right: 0.5rem;
  font-size: 0.68rem;
  color: var(--color1-m-19);
  font-weight: 600;
}

.book-bar-popup-add-btn {
  width: max-content;
  padding: 0.65rem 0.5rem;
  border-radius: 0.15rem;
  border: 0.125rem solid var(--color1-m-18);
  background-color: var(--color1-m-1);
  color: var(--color1-m-18);
  display: flex;
  align-self: center;
  font-weight: 700;
  font-size: 0.75rem;
  margin-top: 0.75rem;
  column-gap: 0.4rem;
}

.book-bar-popup-add-btn:hover {
  background-color: var(--color1-m-2);
  cursor: pointer;
}

/* Home Title Area */
.main-title-container {
  display: grid;
  width: 100%;
  background-color: var(--color1-h-1);

  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
}

.home-company-title-container {
  display: grid;
  justify-self: center;
  justify-items: center;
  align-items: flex-start;
  width: 60%;
  padding: 3rem 0;
  height: max-content;
  /* color: black; */
}

.home-title-text {
  font-family: "Great Vibes", "cursive";
  font-weight: 600;
  font-size: 4.3rem;
  color: var(--color1-m-18);
}

.home-sub-title {
  font-size: 1.35rem;
  text-transform: uppercase;
  padding-top: 2rem;
  font-weight: 700;
  /* color: #808080; */
  color: var(--color1-m-16);
}

.home-content-text {
  /* font-size: 1.025rem; */
  text-align: left;
  justify-self: left;
  padding-top: 2rem;
  font-weight: 500;
  color: var(--color1-m-14);
  line-height: 1.8rem;
  text-align: center;
  font-size: 1.035rem;

  /* text-align: justify; */
}

.home-title-bg-img-left {
  position: absolute;
  justify-self: left;
  height: 25rem;
  transform: scaleX(-1);
}

.home-title-bg-img-right {
  position: absolute;
  justify-self: right;
  height: 25rem;
}

/* Title Contact */
.home-title-contact-container {
  display: grid;
  justify-items: center;
  align-items: center;
  column-gap: 2rem;
  grid-template-columns: max-content max-content max-content;
  padding-top: 3rem;
}

.home-title-contact-item {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 0.55rem;
  align-items: center;
}

.home-title-contact-icon {
  width: 1.25rem;
}

.home-title-contact-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--color1-m-18);
}

/* Home Book Direct */
.home-book-direct-container {
  display: grid;
  width: 100%;
  padding: 3rem 2rem 3rem 4rem;
  background-color: var(--color1-h-2);
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  align-items: center;
}

.home-book-direct-content-container {
  display: flex;
  padding: 4rem 3rem;
  align-items: flex-start;
  flex-direction: column;
}

.home-book-direct-title {
  font-size: 2rem;
  font-weight: bold;
  padding-top: 1rem;
  color: var(--color1-m-18);
}

.home-direct-subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 3rem;
  color: var(--color1-m-16);
}

.home-direct-text {
  font-size: 1rem;
  padding-top: 0.75rem;
  color: var(--color1-m-14);
  font-weight: 600;
}

.home-book-direct-img {
  width: 100%;
  object-fit: cover;
}

/* Home Basic info Counts */
.home-counts-container {
  display: grid;
  /* grid-template-columns: repeat(4, max-content); */
  grid-template-columns: repeat(4, 1fr);
  background-color: var(--color1-h-1);
  /* background-color: hsl(185, 40%, 92%); */
  /* background-color: hsl(210, 67%, 90%); */
  width: 100%;
  align-self: center;
  /* justify-self: center; */
  /* align-items: center; */
  justify-content: center;
  justify-items: center;
  /* column-gap: 6rem; */
  padding: 5rem 15%;
  row-gap: 5rem;
  /* margin: 3rem 0; */
}

.home-counts-item {
  display: grid;
  align-items: center;
  justify-items: center;
  row-gap: 1rem;
}

.home-counts-img {
  width: 3rem;
}

.home-counts-text {
  font-size: 1.5rem;
  color: var(--color1-m-20);
  font-weight: bold;
}

/* Home Amenities */
.home-amenities-container {
  display: grid;
  width: 100%;
  padding: 4rem 15%;
  /* color: var(--color1-m-18); */
  background-color: var(--color1-h-2);
}

.home-amenities-title {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 2rem;
  padding-bottom: 2rem;
  color: var(--color1-m-18);
}

.home-amenities-item-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 2rem;
  align-items: center;
  justify-items: left;
}

.home-amenities-item {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0.75rem;
}

.home-amenities-item-icon {
  width: 1.85rem;
}

.home-amenities-item-text {
  font-weight: bold;
  font-size: 0.98rem;
  color: var(--color1-m-16);
}

/* Rooms */
.home-rooms-container {
  display: grid;
  width: 100%;
  background-color: var(--color1-h-1);
  padding: 0 2rem 3rem 2rem;
  place-items: center;
}

.home-rooms-title {
  font-size: 2rem;
  font-weight: bold;
  padding-top: 3rem;
  color: var(--color1-m-20);
  border-bottom: 1px solid var(--color1-m-15);
  padding-bottom: 0.7rem;
}

.home-rooms-item-container {
  display: grid;
  width: 100%;
  column-gap: 2rem;
  row-gap: 2rem;
  padding: 2rem 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 2.7rem;
}

.home-rooms-item {
  display: grid;
  width: 100%;
  row-gap: 1rem;
  position: relative;
}

.home-rooms-item-image-container {
  position: relative;
}
/* https://www.apcey.com/icons/persons.svg */
.home-rooms-item-img {
  width: 100%;
  aspect-ratio: 3/2;
  /* height: 21rem; */
  border: 0.186rem solid var(--color1-m-12);

  object-fit: cover;
}

.home-rooms-magnify-icon {
  width: 5.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
  cursor: pointer;
  /* z-index: 100000; */
}

.home-rooms-item-img:hover {
  filter: brightness(1.1);
  cursor: pointer;
}

.home-rooms-item-image-container:hover .home-rooms-magnify-icon {
  opacity: 1;
  z-index: 100;
}

.home-rooms-item-title {
  font-size: 1.35rem;
  font-weight: bold;
  justify-self: center;
  color: var(--color1-m-18);
}

.home-rooms-item-text {
  font-size: 0.91rem;
  font-weight: 600;
  justify-self: center;
  color: var(--color1-m-16);
}

/* Reviews */
.home-reviews-container {
  display: grid;
  width: 100%;
  padding: 4rem 0;
  color: #000;
  background-color: var(--color1-h-1);
  /* background-color: var(--color1-h-2); */
  /* column-gap: 2rem; */
  color: white;
}

.home-reviews-title {
  /* font-size: 2.5rem;
  text-transform: uppercase;
  justify-self: center;
  font-weight: 100; */
  font-size: 2rem;
  font-weight: bold;
  justify-self: center;
  /* padding-top: 1rem; */
  /* text-transform: uppercase; */
  /* padding-bottom: 3rem; */
  color: var(--color1-m-18);
  border-bottom: 1px solid var(--color1-m-15);
  padding-bottom: 0.7rem;
}

.home-reviews-items-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 3rem;
}

.home-reviews-item {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 8fr 1fr;
  padding: 3.25rem 2rem;
  justify-items: center;
}

.home-review-content-layout {
  display: grid;
  justify-items: center;
}

.home-reviews-bg-light {
  background-color: var(--color1-m-16);
}

.home-reviews-bg-dark {
  background-color: var(--color1-m-20);
}

.home-reviews-text {
  font-size: 1rem;
  text-align: justify;
  color: var(--color1-m-3);
}

.home-reviews-person {
  padding-top: 2rem;
  text-transform: uppercase;
  font-size: 0.84rem;
  color: var(--color1-m-3);
}

.home-reviews-date {
  font-size: 0.75rem;
  padding-top: 0.45rem;
  text-transform: uppercase;
  color: var(--color1-m-3);
}

.home-reviews-quote-img {
  width: 1rem;
}

/* Location Map */
.home-location-photo-container {
  display: grid;
  place-items: center;
  padding: 4rem;
  background-color: var(--color1-h-2);
  width: 100%;
}

.home-location-title {
  font-size: 2rem;
  font-weight: bold;
  /* padding-top: 3rem; */
  color: var(--color1-m-20);
  border-bottom: 1px solid var(--color1-m-15);
  padding-bottom: 0.7rem;
  text-transform: uppercase;
}

.home-location-img {
  width: 90%;
  /* width: 80vw; */
  object-fit: cover;
  border: 0.186rem solid var(--color1-m-12);
  border-radius: 0.15rem;
  margin-top: 3rem;
}

/* Booking new */

/* Small Mobiles */
@media screen and (max-width: 600px) {
  html {
    font-size: 0.35em;
  }

  .hero-img {
    max-height: 42rem;
  }

  .home-location-photo-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .home-reviews-item {
    padding: 1.5rem 0.35rem;
  }

  .home-rooms-item-img {
    aspect-ratio: 1/1;
  }

  .home-reviews-quote-img {
    width: 0.75rem;
  }

  .book-bar-container {
    font-size: 1.3rem;
    /* width: 100%; */
  }

  .book-bar-popup-ui-container {
    width: 85%;
  }
}

/* Mobiles */
@media only screen and (min-width: 600px) {
  html {
    font-size: 0.38em;
  }

  .hero-img {
    max-height: 50rem;
  }
}

/* Laptops */
@media only screen and (min-width: 768px) {
  html {
    font-size: 0.5em;
  }
}

/* Large Laptops */
@media only screen and (min-width: 992px) {
  html {
    font-size: 0.7em;
  }
}

/* HD Screens */
@media only screen and (min-width: 1200px) {
  html {
    font-size: 0.76em;
  }
}

/* Full HD Screens */
@media only screen and (min-width: 1920px) {
  html {
    font-size: 1em;
  }
}

/* 4K (Ultra HD) Screens */
/* @media only screen and (min-width: 1920px) {
  html {
    font-size: 1.1em;
  }
} */
